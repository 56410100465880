@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto Condensed";
}

.rhap_container.rhap_loop--off.rhap_play-status--paused {
  border-radius: 10px;
}
.rhap_controls-section {
  justify-content: flex-end !important;
}
.rhap_progress-container {
  margin: 0 calc(20px + 1%) !important;
}
.rhap_progress-indicator {
  width: 10px !important;
  height: 10px !important;
  margin-left: -10px !important;
  top: -2.8px !important;
}

body {
  font-family: montserrat, sans-serif;
  width: 100%;
  min-height: 100vh;
}

:root {
  --primary: #ffce00;
  --secondary: #fe4880;
  --dark: #212121;
  --light: #f3f3f3;
}

.card {
  /* margin: 100px auto 0; */
  width: 100%;
  height: 465px;
  perspective: 1000px;
}

.card__inner {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
  transform: rotateY(180deg);
}

.card__inner.is-flipped {
  transform: rotateY(0deg);
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  border-radius: 16px;
  box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
}

.card__face--front {
  background-image: linear-gradient(
    to bottom right,
    var(--primary),
    var(--secondary)
  );
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__face--front h2 {
  color: #fff;
  font-size: 32px;
  padding: 0 0.8rem;
}

.card__face--back {
  background-color: var(--light);
  transform: rotateY(180deg);
}

.card__content {
  width: 100%;
  height: 100%;
}

.card__header {
  position: relative;
  padding: 20px 20px 30px;
  border-radius: 0px 0px 50% 0px;
  background-image: linear-gradient(
    to bottom left,
    var(--primary) 10%,
    var(--secondary) 115%
  );
}

.card__header:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0px 0px 50% 0px;
}

.pp {
  display: block;
  width: 128px;
  height: 128px;
  margin: 0 auto 30px;
  border-radius: 50%;
  background-color: #fff;
  border: 5px solid #fff;
  /* object-fit: cover; */
}
.title_parent {
  width: 74%;
  /* background: red; */
  margin: 0 auto;
  border-radius: 10px;
  white-space: nowrap;
  padding: 0 0.4rem;
}

.marquee {
  line-height: 50px;
  /* background-color: red; */
  color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee h2 {
  /* display: inline-block;
  padding-left: 100%; */
  animation: marquee 20s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

.card__header h2 {
  color: #fff;
  font-size: 32px;
  font-weight: 900;
  /* text-transform: uppercase; */
  text-align: center;
  margin: 0 auto;
  width: fit-content;
}

.card__body {
  padding: 30px;
}

.card__body h3 {
  color: var(--dark);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

.card__body p {
  color: var(--dark);
  font-size: 18px;
  line-height: 1.4;
}

.card__face.card__face--back .card__body {
  /* height: 250px; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.card__body > div > p {
  font-size: 0.9rem;
  height: 90px;
  overflow-y: auto;
  margin-bottom: 0.4rem;
  font-family: "Roboto Condensed", sans-serif;
}

.section-cat {
  position: relative;
}
.section-cat * {
  z-index: 1;
}
.section-cat:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.85;
}
.footer-sec span {
  font-size: 1.3rem;
  color: #fff;
}
.footer-sec {
  padding: 0 !important;
  padding-bottom: 1.2rem !important;
  background: #39a189;
  border-radius: 0;
  text-align: center !important;
}
.Footer-Divider {
  margin-top: 0 !important;
}
.swiper-button-prev {
  left: -2px;
}
.swiper-button-next {
  right: -2px;
}
.view-all a {
  border: 1px solid #39a189;
  color: #39a189;
  font-weight: bold;
  padding: 0.7rem 2rem;
  border-radius: 50px;
}

.view-all {
  position: absolute;
  display: block;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
}

.view-all a:hover {
  background: #39a189;
  color: #fff;
  border-color: #2e836f;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 251, 255, 0.2);
  border-right: 1.1em solid rgba(0, 251, 255, 0.2);
  border-bottom: 1.1em solid rgba(0, 251, 255, 0.2);
  border-left: 1.1em solid #00fbff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.header-sec:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #080808;
  position: absolute;
  opacity: 0.55;
  top: 0;
  left: 0;
}

.header-sec {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: bottom;
}
h1.hTitle {
  position: absolute;
  bottom: 0;
  font-size: 4em;
}
.chapterTab {
  transition: 0.1s ease-in-out;
}
.chapterTabActive {
  background-color: #00c2b9 !important;
  color: #fff !important;
  font-weight: bold !important;
  border-radius: 10px 10px 0px 0px !important;
}
.activeColor {
  border: 4px solid ;
  border-color: #00c2b9 !important;
  background-color: #00c2b9;
  color: #fff;
}



.card__body p::-webkit-scrollbar {
  width: 0.4rem;
}
 
.card__body p::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
 
.card__body p::-webkit-scrollbar-thumb {
  background-color: #00c2b9;
  outline: 1px solid #00c2b9;
  border-radius: 5px;
}



.footer-sec a > svg {
  fill: #fff;
}